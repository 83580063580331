import React, { useEffect, useMemo, useCallback, lazy, Suspense } from 'react';
import { KibbleThemeProvider, theme as kibbleTheme } from '@televet/kibble-ui/build/theme';
import * as Sentry from '@sentry/react';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import {
  IWindowMessageData,
  ISelectRequestTypePayload,
  ISetIsMobilePayload,
} from 'shared/interfaces/IWindowMessageData';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import {
  useGetClinicDirectBookingSettingsQuery,
  useGetClinicWidgetSettingQuery,
  WidgetRequestType,
} from 'shared/types/graphql';
import useGA, { GATrack } from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { commitInfo } from 'commitInfo';
import useWidgetTheme from 'theme/useWidgetTheme';
import { useWidgetStore } from 'state/useWidgetStore';
import useIsUrlWhitelisted from 'shared/hooks/useIsUrlWhitelisted';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { getIsClientCreationSupported } from 'shared/utils/getIsClientCreationSupported';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { InitializeState } from 'state/types/initialize';

const PetPortalWidget = lazy(() => import('./PetPortalWidget'));
const ClinicSiteWidget = lazy(() => import('./ClinicSiteWidget'));

declare global {
  interface Window {
    parentIFrame: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const appInfo = { appVersion: parseInt(commitInfo.commitNumber) / 100, ...commitInfo };

const Application = (): JSX.Element | null => {
  const {
    clinicId,
    parentUrl,
    isInline,
    updateWidgetState,
    onToggle,
    initialize,
    updateDbState,
    goToNextStep,
    isPetPortal,
    setSelectedRequest,
  } = useWidgetStore((state) => state);
  const { isFeatureEnabled } = useFeatureFlag(clinicId);

  useGA({ clinicId });

  const isDirectBookingEnabled = useMemo(() => isFeatureEnabled(FeatureFlagName.DirectBooking), [isFeatureEnabled]);

  const {
    data: clinicWidgetSettingData,
    loading: isClinicWidgetSettingLoading,
    error: clinicWidgetSettingError,
  } = useGetClinicWidgetSettingQuery({
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    skip: !clinicId,
    onError: (e) => {
      console.error(e);
      Sentry.captureException(e);
    },
    onCompleted: (data) => {
      const clinicData = data.findFirstClinicWidgetSetting?.clinic[0];

      if (clinicData) {
        updateWidgetState({
          clinicName: clinicData.name,
          clinicId: clinicData.id,
          clinicEmail: clinicData.clinicEmail,
          clinicPhone: clinicData.phone,
        });
      }

      let isClientPatientCreationSupported = false;

      if (isFeatureEnabled(FeatureFlagName.DirectBookingCreateClientAndPatient)) {
        isClientPatientCreationSupported = getIsClientCreationSupported(clinicData?.integrations || []);
      }

      const requestAppointmentRequest = data.findFirstClinicWidgetSetting?.clinicWidgetRequestTypes.find(
        (requestType) => requestType.requestType === WidgetRequestType.RequestAppointment,
      );

      updateDbState({
        canCreateClientPatient: isClientPatientCreationSupported,
        requestAppointmentRequest: requestAppointmentRequest,
      });
    },
    variables: {
      where: {
        clinic: {
          some: {
            id: {
              equals: clinicId,
            },
          },
        },
      },
    },
  });

  useGetClinicDirectBookingSettingsQuery({
    variables: {
      data: {
        clinicId,
      },
    },
    onCompleted: (data) => {
      if (!data) return;

      updateDbState({
        isOtcEnabled: data.getClinicDirectBookingSettings?.isOneTimePasswordEnabled || false,
      });
    },
    skip: !clinicId || !isDirectBookingEnabled,
    fetchPolicy: GraphQLFetchPolicy.CacheAndNetwork,
  });

  const isUrlWhitelisted = useIsUrlWhitelisted({
    url: parentUrl,
    whitelistedUrls: clinicWidgetSettingData?.findFirstClinicWidgetSetting?.whiteLabelUrls,
  });

  const widgetSettings = useMemo(() => clinicWidgetSettingData?.findFirstClinicWidgetSetting, [
    clinicWidgetSettingData,
  ]);

  const theme = useWidgetTheme({
    primaryColor: widgetSettings?.primaryColor || kibbleTheme.colors.primary['600'],
    secondaryColor: widgetSettings?.secondaryColor || kibbleTheme.colors.secondary['500'],
  });

  const handleMessage = useCallback(
    (e: MessageEvent): void => {
      const data: IWindowMessageData<InitializeState & ISelectRequestTypePayload & ISetIsMobilePayload> = e.data;

      switch (e.data.event) {
        case WindowMessageEvent.InitializeWidget:
          initialize({
            clinicId: data.payload.clinicId,
            parentUrl: data.payload.parentUrl,
            isMobile: data.payload.isMobile,
            isInline: data.payload.isInline,
            isPetPortal: data.payload.isPetPortal,
            isOpen: data.payload.isOpen,
            showPreview: data.payload.showPreview,
            petParentId: data.payload.petParentId,
          });

          break;
        case WindowMessageEvent.OpenWidget:
          onToggle(true);

          break;
        case WindowMessageEvent.CloseWidget:
          onToggle(false);

          break;
        case WindowMessageEvent.ToggleWidget:
          onToggle();

          break;
        case WindowMessageEvent.SelectRequestType:
          if (!data.payload.requestType) return;

          // Since we aren't specifying the actual requset id we are just choosing the first request to match the request type
          const firstMatchingRequest = widgetSettings?.clinicWidgetRequestTypes?.find(
            (request: ClinicWidgetRequestTypeResponse) => request?.requestType === data.payload.requestType,
          );

          if (firstMatchingRequest) {
            setSelectedRequest(firstMatchingRequest);
            goToNextStep();
          }

          break;
        case WindowMessageEvent.SetIsMobile:
          if (data.payload.hasOwnProperty('isMobile')) {
            initialize({ isMobile: data.payload.isMobile });
          }
          break;
        default:
          return;
      }
    },
    [initialize, onToggle, widgetSettings?.clinicWidgetRequestTypes, setSelectedRequest, goToNextStep],
  );

  // Post widget ready message event
  useEffect(() => {
    window.parent.postMessage({ event: WindowMessageEvent.OnWidgetReady }, '*');
  }, []);

  // Listen for new message events
  useEffect(() => {
    // Wait for settings to load before triggering events
    if (isClinicWidgetSettingLoading) return;

    window.addEventListener('message', (e: MessageEvent) => {
      handleMessage(e);
    });

    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
    // Disabling so we don't need to include handleMessage which causes the event listener to fire multiple times in a row
    // eslint-disable-next-line
  }, [isClinicWidgetSettingLoading]);

  // Track widget initialization
  useEffect(() => {
    if (!widgetSettings || !Object.keys(widgetSettings).length) return;

    const requestTypes = widgetSettings.clinicWidgetRequestTypes?.map(({ displayName, requestType, order }) => ({
      displayName,
      requestType,
      order,
    }));

    GATrack(GA4Events.IMPRESSION, {
      clinicId,
      url: parentUrl,
      requestTypes: requestTypes.map((rt) => rt.requestType).join(', '),
      isInline,
      isPetPortal,
    });

    window.parent.postMessage({ event: WindowMessageEvent.OnWidgetInitialized, payload: { requestTypes } }, '*');
  }, [widgetSettings, parentUrl, clinicId, isInline, isPetPortal]);

  if (isClinicWidgetSettingLoading || clinicWidgetSettingError || !widgetSettings?.isEnabled || !isUrlWhitelisted) {
    return null;
  }

  return (
    <KibbleThemeProvider theme={isPetPortal ? undefined : theme}>
      {/* null fallback because it loads so quickly that an intermediate render looks bad */}
      <Suspense fallback={null}>
        {isPetPortal ? (
          <PetPortalWidget widgetSettings={widgetSettings} />
        ) : (
          <ClinicSiteWidget widgetSettings={widgetSettings} />
        )}
      </Suspense>
    </KibbleThemeProvider>
  );
};

export default Application;
