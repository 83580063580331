import { useMemo } from 'react';
import { env } from 'env';

interface UseIsUrlWhitelistedProps {
  url: string;
  whitelistedUrls: string[];
}

const useIsUrlWhitelisted = ({ url, whitelistedUrls }: UseIsUrlWhitelistedProps): boolean => {
  const isOnHostedPreviewPage = useMemo(() => {
    if (!url) return false;

    return [
      'connect.televet.com',
      'connect-demo.televet.com',
      'connect-develop.televet.com',
      'widget.demo.dev.otto.vet',
    ].includes(new URL(url).hostname);
  }, [url]);

  const isInQaLane = useMemo(() => {
    if (!url) return false;

    // Matches qa and test lanes for widget and pet portal
    const qaUrlRegex = /(widget|pets)\.(qa(\d+)|test)\.dev\.otto\.vet/;

    return qaUrlRegex.test(new URL(url).hostname);
  }, [url]);

  const isOnHostedPetPortal = useMemo(() => {
    if (!url) return false;

    return ['pet.otto.vet', 'care-demo.televet.com', 'pet.demo.dev.otto.vet'].includes(new URL(url).hostname);
  }, [url]);

  const isUrlWhitelisted = useMemo(() => {
    if (!whitelistedUrls || !url) return false;

    const isRunningLocally = env.NODE_ENV !== 'production' && new URL(url).hostname === 'localhost';

    if (isRunningLocally || isOnHostedPreviewPage || isInQaLane || isOnHostedPetPortal) {
      return true;
    }

    const _whitelistedUrls: string[] = whitelistedUrls;

    return _whitelistedUrls.some(
      (url) =>
        url === '*' ||
        new URL(url).hostname.replace(/^(www\.)/i, '') === new URL(url).hostname.replace(/^(www\.)/i, ''),
    );
  }, [whitelistedUrls, url, isOnHostedPreviewPage, isInQaLane, isOnHostedPetPortal]);

  return isUrlWhitelisted;
};

export default useIsUrlWhitelisted;
