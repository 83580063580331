export enum WindowMessageEvent {
  InitializeWidget = 'OTTO_WIDGET_INITIALIZE',
  OpenWidget = 'OTTO_WIDGET_OPEN',
  CloseWidget = 'OTTO_WIDGET_CLOSE',
  ToggleWidget = 'OTTO_WIDGET_TOGGLE',
  SelectRequestType = 'OTTO_WIDGET_SELECT_REQUEST_TYPE',
  SelectRequestById = 'OTTO_WIDGET_SELECT_REQUEST_BY_ID',
  SetIsMobile = 'OTTO_WIDGET_SET_IS_MOBILE',
  OnWidgetReady = 'OTTO_WIDGET_READY',
  OnWidgetInitialized = 'OTTO_WIDGET_INITIALIZED',
  RequestSubmitted = 'OTTO_WIDGET_REQUEST_SUBMITTED',
  TelevetRequestSubmitted = 'TELEVET_WIDGET_REQUEST_SUBMITTED',
  OpenTeletriageModal = 'OTTO_WIDGET_OPEN_TELETRIAGE_MODAL',
}
